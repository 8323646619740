import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import Banner from "../components/Content/Banner/Index";
import LeadIn from "../components/Content/LeadIn/Index";
import FeatureColumn from "../components/Content/FeatureColumn/Index";
import Col2 from "../components/Content/TextMedia/Col-2";
import ImageWrapper from "../components/Content/TextMedia/ImageWrapper";

// data
import data from "../pages/static-pages/landing/tools-and-accessories.yaml";
import Col1 from "../components/Content/TextMedia/Col-1";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="Tools and Accessories"
        description="Get the most out of your press fittings with the right tools &amp; accessories."
      />
      <Banner
        type="landing"
        title="Tools &amp; Accessories"
        summary="Get the most out of your press fittings with the right tools &amp; accessories."
        featureMediaUrl="/banner/landing-tools-us.jpg"
        featureMediaTitle="m12 tool in hand used on copper piping"
        removePadding={true}
      />
      <Col1
        brandMediaUrl="/brands/logo-m12.svg"
        brandMediaTitle="M12"
        brandMediaWidth="155px"
        heading="Tool FORCE LOGIC M12 Press Tools"
        summary="FORCE LOGIC™ is engineered to not only improve upon, but fundamentally change the way high-force tools are used in the field. Through thoughtful design focused on workflow, each tool simplifies a complicated process to deliver the best user experience of its kind."
      />
      <ImageWrapper
        imageSrc="/products/tools/m12.jpg"
        zoomSrc="/products/tools/m12.jpg"
        imageAlt="Romax Compact TT with features"
        customBg="#FFFFFF"
      />
      <FeatureColumn
        grid="medium"
        data={data.columns1}
        footnote="<strong>To find out more about the M12, visit maX</strong>"
        buttonValue="Visit website"
        buttonUrl="https://morrisonsupply.reece.com/product/MSC-3001"
        buttonTarget="_blank"
      />
      <Col1
        removePadding={true}
        brandMediaUrl="/brands/logo-m18.svg"
        brandMediaTitle="M18"
        brandMediaWidth="155px"
        heading="Tool FORCE LOGIC M18 Press Tools"
        summary="Our M18™ FORCE LOGIC™ Press Tool with ONE-KEY™ is the industry’s first press tool with remote tracking, management, and lockout and is the smallest, most intelligent press tool on the market."
      />
      <ImageWrapper
        imageSrc="/products/tools/m18.jpg"
        zoomSrc="/products/tools/m18.jpg"
        imageAlt="Romax 4000 with features"
        customBg="#FFFFFF"
      />
      <FeatureColumn
        grid="medium"
        data={data.columns2}
        footnote="<strong>To find out more about the M18, visit maX </strong>"
        buttonValue="Visit website"
        buttonUrl="https://morrisonsupply.reece.com/product/MSC-1408003"
        buttonTarget="_blank"
      />
      <LeadIn
        anchor="tool-rental"
        brandHeading="Tool Rental"
        divider={true}
        paddingSize="xsmall"
      />
      <Col2
        summary="
          <h2>Access the tools you need with convenience</h2>
          <p>The tool rental service is available at select branches across the USA and offers customers access to hire a range of tools, including Milwaukee press tools and jaws. Rented items can be returned to any branch and they will take care of the rest for you.</p>
          <p>Find out more in-store</p>
        "
        backgroundMediaUrl="/products/tools/tools-rental.jpg"
        backgroundMediaTitle="Reece tool hire service"
        mediaPositionClass="right"
      />
      <LeadIn
        anchor="approved-tools"
        brandHeading="Approved Tools"
        divider={true}
        paddingSize="xsmall"
      />
      <Col2
        summary="
          <p><strong>The following tools have been tested on and are guaranteed to perform well with >B< Press fittings. Using any other tool can void your Conex Bänninger warranty.</strong></p>
          <ul>
            <li>Milwaukee M12 Press Tool</li>
            <li>Milwaukee M18 Press Tool</li>
            <li>Ridgid RP 200</li>
            <li>Ridgid RP 210</li>
            <li>Ridgid RP 240</li>
            <li>Ridgid RP 241</li>
            <li>Ridgid RP 320</li>
            <li>Ridgid RP 330</li>
            <li>Ridgid RP 340</li>
            <li>Ridgid RP 350</li>
            <li>Ridgid RP 351</li>
          </ul>
        "
        backgroundMediaUrl="/products/tools/tools-approved.jpg"
        backgroundMediaTitle="M12 Press Tools being used by person"
        mediaPositionClass="left"
        footnote="It is imperative that your tool works in unison with your press fittings. Therefore, it is recommended to use Milwaukee press tools when installing >B< Press for the best press solution."
      />
    </Layout>
  );
};

export default IndexPage;
