import React from "react";
import { withPrefix } from "gatsby";
import InnerImageZoom from "react-inner-image-zoom";

// styles
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

interface Props {
  imageSrc: string;
  zoomSrc: string;
  imageAlt: string;
  customBg?: string;
}

const ImageWrapper: React.FC<Props> = (props) => {
  const { imageSrc, zoomSrc, imageAlt, customBg } = props;

  return (
    <section className="c-viewport uk-section uk-section-image" style={{background: customBg}}>
      <div className="uk-container">
        <div className="uk-width-1-1 uk-width-1-1@m uk-width-3-4@l uk-margin-auto">
          <InnerImageZoom src={withPrefix(imageSrc)} zoomSrc={withPrefix(zoomSrc)} alt={imageAlt} />
        </div>
      </div>
    </section>
  );
};

export default ImageWrapper;
